<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">商家用户</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="100" prop="merchant_user_id" label="商家ID"></el-table-column>
				<el-table-column align="center" label="头像" width="90">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="昵称">
					<template slot-scope="scope">
						<span style="">{{scope.row.user.nickName}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="商家名称" prop="merchant_name"></el-table-column>
				<el-table-column align="center" label="logo" width="90">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.logo">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="商家电话" prop="mobile"></el-table-column>
				<el-table-column align="center" label="商家微信" prop="wechat"></el-table-column>
				<el-table-column align="center" label="加入时间" width="180">
					<template slot-scope="scope">
						<span style="">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="270">
					<template slot-scope="scope">
						<!-- <router-link
							:to="{name:'user.merchant/coupon',params: {merchant_user_id:scope.row.merchant_user_id}}">
							<el-button type="text" size="mini" class="table-btn">优惠券</el-button>
						</router-link> -->
						<el-button type="text" size="mini" class="table-btn" @click="detail(scope.row.merchant_user_id)">
							详情</el-button>
						<router-link :to="{name:'coupon/index',params: {merchant_user_id:scope.row.merchant_user_id}}">
							<el-button type="text" size="mini" class="table-btn">优惠券</el-button>
						</router-link>
						<el-button type="text" size="mini" class="table-btn" @click="clerk(scope.row.merchant_user_id)">
							员工</el-button>
						<el-button type="text" size="mini" class="table-btn" @click="client(scope.row.merchant_user_id)">
							客户</el-button>
						<el-button @click="deleteItem(scope.row)" type="text" size="mini" class="table-last-btn">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog title="员工" :visible.sync="clerkVisible" append-to-body>
				<el-table border class="mt-3" :data="clerkLists.data" style="width: 100%" :default-expand-all="true">
					<el-table-column label="核销员ID" prop="clerk_id"></el-table-column>
					<el-table-column label="微信头像">
						<template slot-scope="scope">
							<app-image mode="aspectFill" style="float: left;margin-right: 8px"
								:src="scope.row.user.avatarUrl"></app-image>
						</template>
					</el-table-column>
					<el-table-column label="微信昵称">
						<template slot-scope="scope">
							<div flex="dir:left cross:center">{{scope.row.user.nickName}}</div>
						</template>
					</el-table-column>
					<el-table-column label="类型">
						<template slot-scope="scope">
							<el-tag size="mini" type="danger" v-if="scope.row.type == 10">管理员</el-tag>
							<el-tag size="mini" v-else>员工</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="添加时间" prop="create_time"></el-table-column>
				</el-table>
				<div style="height: 30px;" class="mt-2 px-2">
					<el-pagination :page-size="clerkLists.per_page" style="display: inline-block;float: right;"
						background @current-change="clerkPageChange" layout="prev, pager, next"
						:total="clerkLists.total">
					</el-pagination>
				</div>
			</el-dialog>
			<el-dialog title="客户" :visible.sync="clientVisible" append-to-body>
				<el-table border class="mt-3" :data="clientLists.data" style="width: 100%" :default-expand-all="true">
					<el-table-column label="客户ID" prop="merchant_client_id"></el-table-column>
					<el-table-column label="微信头像">
						<template slot-scope="scope">
							<app-image mode="aspectFill" style="float: left;margin-right: 8px"
								:src="scope.row.user.avatarUrl"></app-image>
						</template>
					</el-table-column>
					<el-table-column label="微信昵称">
						<template slot-scope="scope">
							<div flex="dir:left cross:center">{{scope.row.user.nickName}}</div>
						</template>
					</el-table-column>
					<el-table-column label="添加时间" prop="create_time"></el-table-column>
				</el-table>
				<div style="height: 30px;" class="mt-2 px-2">
					<el-pagination :page-size="clientLists.per_page" style="display: inline-block;float: right;"
						background @current-change="clientPageChange" layout="prev, pager, next"
						:total="clientLists.total">
					</el-pagination>
				</div>
			</el-dialog>
			<el-dialog title="详情" :visible.sync="merchantVisible" append-to-body>
				<template v-if="merchant != null">
					<el-divider><span style="font-weight: 800;">商家详情信息</span></el-divider>
					<p>商家名称：{{merchant.merchant_name}}</p>
					<p class="d-flex align-items-center">LOGO：<viewer><img class="ml-3" style="width: 45px;height: 45px;"
								:src="merchant.logo"></viewer>
					</p>
					<p>商家电话：{{merchant.mobile}}</p>
					<p>微信号：{{merchant.wechat}}</p>
					<p>商家地址：{{merchant.address}}</p>
					<p>详细地址：{{merchant.address_detail}}</p>
					<el-divider><span style="font-weight: 800;">图文详情</span></el-divider>
					<template v-for="(item,index) in merchant.imageText">
						<template v-if="item.type == 10">
							<p>{{item.guidance}}：{{item.content}}</p>
						</template>
						<template v-else>
							<p class="d-flex align-items-center">
								<viewer><img class="mr-2" style="width: 45px;height: 45px;" :src="item.image"></viewer>
								<span>{{item.content}}</span>
							</p>
						</template>
					</template>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				clerkLists: [],
				clientLists: [],
				clerkSearch: {
					s: 'store/user.merchant.clerk/index',
					keyword: '',
					merchant_user_id: 0,
					page: 1,
				},
				clientSearch: {
					s: 'store/user.merchant.client/index',
					keyword: '',
					merchant_user_id: 0,
					page: 1,
				},
				clerkVisible: false,
				clientVisible: false,
				search: {
					s: 'store/user.merchant/index',
					keyword: '',
					page: 1,
				},
				merchant_user_id: 0,
				merchantVisible: false,
				merchant: null,
			}
		},
		created() {
			this.getList();
		},
		methods: {
			detail(merchant_user_id){
				this.merchant_user_id = merchant_user_id;
				this.merchantVisible = true;
				this.getMerchantDetail();
			},
			getMerchantDetail() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/user.merchant/detail',
						merchant_user_id: this.merchant_user_id
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.merchant = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			clerkPageChange(page) {
				this.clerkSearch.page = page;
				this.getClerkList();
			},
			clientPageChange(page) {
				this.clientSearch.page = page;
				this.getClientList();
			},
			clerk(merchant_user_id) {
				this.clerkSearch.merchant_user_id = merchant_user_id
				this.clerkVisible = true
				this.getClerkList();
			},
			client(merchant_user_id){
				this.clientSearch.merchant_user_id = merchant_user_id
				this.clientVisible = true
				this.getClientList();
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getClerkList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.clerkSearch,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.clerkLists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getClientList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.clientSearch,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.clientLists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该商家吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/user.merchant/delete',
							merchant_user_id: data.merchant_user_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
